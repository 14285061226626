<template>
  <no-subcription v-if="!getSubscription"/>
    <div v-else class="page-add-inventory">
        <app-loading v-show="loadingData"/>
        <v-container v-if="!loadingData">
            <v-card>
                <v-card-title>
          <p>{{
                  $vuetify.lang.t("$vuetify.menu.sell_product")
              }}</p>
                </v-card-title>
                <v-card-text>
                    <v-tabs
                        v-model="tab"
                        background-color="transparent"
                        color="basil"
                        grow
                    >
                        <v-tab
                            v-for="item in items"
                            :key="item.text"
                        >
                            <v-icon>{{ item.icon }}</v-icon>
                            {{ item.text }}
                        </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tab">
                        <v-tab-item>
                            <v-card
                                color="basil"
                                flat
                            >
                                <v-card-text>
                                  <resume-filter-component @updateFilter="loadData"/>
                                </v-card-text>
                                <v-expansion-panels
                                    v-model="panel"
                                    style="margin: 0"
                                    multiple
                                >
                                    <v-expansion-panel style="margin: 0">
                                      <v-expansion-panel-header>
                                        <div>
                                          <v-icon>mdi-chart-line</v-icon>
                                          <span style="text-transform: uppercase;font-weight: bold"> {{ $vuetify.lang.t("$vuetify.report.graphics") }} </span>
                                        </div>
                                      </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-row>
                                                <v-col
                                                    class="py-0"
                                                    cols="12"
                                                    :md=" localSalesByProduct.length > 0 ? 5 : 12"
                                                >
                                                    <v-card
                                                        class="mx-auto"
                                                        tile
                                                    >
                                                        <v-list dense>
                                                            <v-subheader>
                                                                {{
                                                                    localSalesByProduct.length > 0 ? $vuetify.lang.t("$vuetify.report.top5Articles")
                                                                        : $vuetify.lang.t("$vuetify.report.noTop5")
                                                                }}
                                                            </v-subheader>
                                                            <v-subheader>
                                                                <v-slider
                                                                    v-if="localSalesByProduct.length > 0"
                                                                    v-model="sliderModel"
                                                                    :label="$vuetify.lang.t('$vuetify.actions.show')"
                                                                    :thumb-color="'green lighten-1'"
                                                                    thumb-label="always"
                                                                    :max="max"
                                                                    :min="min"
                                                                ></v-slider>
                                                            </v-subheader>
                                                            <v-list-item-group color="primary">
                                                                <v-list-item
                                                                    v-for="(item, i) in localSalesByProduct.slice( 0, sliderModel)"
                                                                    :key="i"
                                                                >
                                                                    <v-list-item-icon>
                                                                        <v-avatar
                                                                            style="margin-left: 0px;margin-right: 0px"
                                                                            :color="(item.color && item.images.length === 0 )? item.color : ''"
                                                                        >
                                                                            <v-img
                                                                                v-if="item.images.length > 0"
                                                                                :src="item.images.filter(im=>im.default)[0].path"
                                                                            />
                                                                        </v-avatar>
                                                                    </v-list-item-icon>
                                                                    <v-list-item-content>
                                                                        <v-list-item-title>
                                                                            <p>{{ item.name }}</p>
                                                                        </v-list-item-title>
                                                                        <v-list-item-subtitle>
                                                                            <p><b>{{ $vuetify.lang.t('$vuetify.dashboard.sales') }}</b>: {{ item.cantTransactions + ' ' + item.um.name }} </p><p><b>{{
                                                                                    $vuetify.lang.t('$vuetify.dashboard.revenue')
                                                                                }}</b>: {{
                                                                                    parseFloat(item.margin).toFixed(2) + ' ' + user.company.currency
                                                                                }} </p>
                                                                        </v-list-item-subtitle>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                            </v-list-item-group>
                                                        </v-list>
                                                    </v-card>
                                                </v-col>
                                                <v-col
                                                    v-if="localSalesByProduct.length > 0"
                                                    class="py-0"
                                                    cols="12"
                                                    md="7"
                                                >
                                                    <highcharts :options="chartOptions"/>
                                                </v-col>
                                            </v-row>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    <v-expansion-panel style="margin: 0">
                                        <v-expansion-panel-header>
                                            <div>
                                                <v-icon>mdi-table-large</v-icon>
                                                <span style="text-transform: uppercase;font-weight: bold">
                                                  {{ $vuetify.lang.t("$vuetify.panel.basic") }}
                                                </span>
                                            </div>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                          <product-table-info :percent="true" :local-sales-by-product="localSalesByProduct" :currency="user.company.currency"/>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item>
                            <v-card
                                color="basil"
                                flat
                            >
                                <v-card-text v-if="localSalesByProduct.length > 0">

                                  <sale-summary :products-data="getSalesProductData" :currency="user.company.currency" :first-date="firstDate" :second-date="secondDate"/>
                                  <p v-if="localSalesByProduct.length > 0">El(s) artículo(s) más vendido(s):</p>
                                  <list-products v-if="localSalesByProduct.length > 0" :products="localSalesByProduct.slice( 0, sliderModel)"  :currency="user.company.currency" :first-date="firstDate" :second-date="secondDate"/>
                                </v-card-text>
                                <v-card-text v-else>
                                    <p>En el intervalo desde
                                        {{ firstDate.toLocaleDateString() + ' ' + firstDate.getHours() + ':' + firstDate.getMinutes() }}
                                        hasta
                                        {{ secondDate.toLocaleDateString() + ' ' + secondDate.getHours() + ':' + secondDate.getMinutes() }},
                                        no se han efectuado ventas</p>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card-text>
            </v-card>
        </v-container>
    </div>
</template>

<script>

import AppLoading from '../../../components/core/AppLoading'
import { Chart } from 'highcharts-vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import ResumeFilterComponent from './ResumeFilterComponent'
import ListProducts from './summary/ProductsSummary'
import SaleSummary from './summary/SaleSummary'
import ProductTableInfo from './ProductTableInfo'

export default {
  name: 'SalesProduct',
  components: { ProductTableInfo, SaleSummary, ListProducts, ResumeFilterComponent, AppLoading, highcharts: Chart },
  data () {
    return {
      totalSale: 0.00,
      tab: null,
      items: [{
        text: this.$vuetify.lang.t('$vuetify.report.graphics'),
        icon: 'mdi-chart-bar'
      }, {
        text: this.$vuetify.lang.t('$vuetify.menu.resume'),
        icon: 'mdi-file-document'
      }],
      min: 0,
      max: 5,
      sliderModel: 5,
      loadingData: false,
      firstDate: new Date(),
      secondDate: new Date(),
      panel: [0],
      seriesData: [],
      chartOptions: {},
      vBindOption: {
        itemKey: 'no_facture',
        singleExpand: true,
        showExpand: true
      },
      menu: false,
      formRule: this.$rules,
      localShops: [],
      localSalesByProduct: []
    }
  },
  computed: {
    ...mapState('shop', ['shops', 'isShopLoading']),
    ...mapState('sale', ['isTableLoading']),
    ...mapGetters('sale', ['getSalesByProducts', 'getSalesProductData']),
    ...mapGetters('auth', ['user']),
    ...mapState('auth', ['company_subscription']),
    getSubscription () {
      return this.company_subscription ? !this.company_subscription.is_caduced : false
    }
  },
  watch: {
    localSalesByProduct: function () {
      this.max = this.getSalesByProducts.length
      this.sliderModel = this.getSalesByProducts.length >= 5 ? 5 : this.getSalesByProducts.length
    },
    sliderModel: function () {
      this.loadData()
    }
  },
  methods: {
    ...mapActions('shop', ['getShops']),
    ...mapActions('sale', ['getSaleByProduct']),
    async loadData (firstDate, secondDate, localShop) {
      if (firstDate && secondDate && localShop.length > 0) {
        this.firstDate = firstDate
        this.secondDate = secondDate
        await this.getSaleByProduct({
          shops: localShop,
          dates: [this.firstDate, this.secondDate],
          online: this.$route.path.split('online').length > 1
        }).then(() => {
          this.localSalesByProduct = this.getSalesByProducts
        })
        const categories = []
        const series = {
          totalCost: [],
          grossPrice: [],
          totalDiscount: [],
          totalTax: [],
          netPrice: []
        }
        this.localSalesByProduct.slice(0, this.sliderModel).forEach(v => {
          categories.push(v.name)
          series.totalCost.push(v.totalCost)
          series.grossPrice.push(v.grossPrice)
          series.totalDiscount.push(v.totalDiscount)
          series.totalTax.push(v.totalTax)
          series.netPrice.push(v.netPrice)
        })
        this.chartOptions = {
          chart: {
            type: 'column'
          },
          title: {
            text: this.$vuetify.lang.t('$vuetify.report.barGraphics')
          },
          subtitle: {
            text: 'billingmoneyapp.com'
          },
          xAxis: {
            categories: categories,
            crosshair: true
          },
          yAxis: {
            min: 0,
            title: {
              text: this.user.company.currency
            }
          },
          tooltip: {
            headerFormat:
              '<span style="font-size:10px"><b>{point.key}</b></span><table>',
            pointFormat:
              '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y:.1f} ' +
              this.user.company.currency +
              '</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
          },
          plotOptions: {
            column: {
              pointPadding: 0.2,
              borderWidth: 0
            }
          },
          series: [
            {
              name: this.$vuetify.lang.t(
                '$vuetify.variants.total_cost'
              ),
              data: series.totalCost
            },
            {
              name: this.$vuetify.lang.t('$vuetify.report.netPrice'),
              data: series.netPrice
            },
            {
              name: this.$vuetify.lang.t(
                '$vuetify.report.discountsSale'
              ),
              data: series.totalDiscount
            },
            {
              name: this.$vuetify.lang.t(
                '$vuetify.tax.total_pay_tax'
              ),
              data: series.totalTax
            },
            {
              name: this.$vuetify.lang.t('$vuetify.report.grossSale'),
              data: series.grossPrice
            }
          ],
          credits: {
            enabled: false
          }
        }
      }
    },
    rowClick ($event) {
    }
  }
}

</script>

<style scoped></style>
